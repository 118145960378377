import React, {useState, useEffect} from 'react'
import { Image } from "react-bootstrap";
import { map } from "lodash"
import AvatarNoFound from "../../assets/png/avatar-no-found.png"
import { API_HOST } from "../../utils/constant";
import { getUserApi } from "../../api/user"
import moment from 'moment'
import { replaceURLWithHTMLLinks } from "../../utils/functions";

export default function ListTweets({tweets}) {



    return (
        <div className="list-tweets">
            {map(tweets, (tweet, index) => (
                <Tweet
                    key={index}
                    tweet={tweet}
                />
            ))}
        </div>
    )
}

const Tweet = ({tweet}) => {
    const [userInfo, setUserInfo] = useState(null)
    const [avatarUrl, setAvatarUrl] = useState(null)

    useEffect(() => {
        getUserApi(tweet.userId).then(res => {
            setUserInfo(res)
            setAvatarUrl(
                res?.avatar
                    ? `${API_HOST}/obtener-avatar?id=${res.id}`
                    : AvatarNoFound
            )
        })
    }, [tweet])

    return (
        <div className='tweet'>
            <Image className="avatar" src={avatarUrl} roundedCircle />
            <div>
                <div className='name'>
                    {userInfo?.nombre} {userInfo?.apellidos}
                    <span>{ moment(tweet.fecha).calendar()}</span>
                </div>
                <div
                    dangerouslySetInnerHTML={{ __html: replaceURLWithHTMLLinks(tweet.mensaje)}}
                />
            </div>
        </div>
    )
}